import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import instance from "api/globalaxios";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { DEV_WEBNYAY_URL, WEBNYAY_URL } from "app/constants";
import { isDev } from "Routes/Routes";
import { getRealhost } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    backgroundColor: "#f4f6f8",
    minHeight: "100vh",
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  card: {
    maxWidth: 500,
    margin: "auto",
    borderRadius: 12,
    padding: "1rem",
    boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
  },
  textField: {
    margin: theme.spacing(2, 0),
  },
  createMeetingButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  copyText: {
    fontSize: "1.2rem",
    padding: "6px",
    cursor: "pointer",
  },
  copied: {
    color: "green",
  },
}));

export default function CreateDirectMeeting({ profile, showExcelUpload }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [errorType, setErrorType] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedPadUrl, setIsCopiedPadUrl] = useState(false);
  const [meetingUrl, setMeetingUrl] = useState();
  const [padUrl, setPadUrl] = useState();
  const [disputeId, setDisputeId] = useState(null);
  const [padId, setPadId] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    if (disputeId || padId) {
      if (window.location.hostname == "localhost") {
        setMeetingUrl(`http://${window.location.host}/meeting/${disputeId}`);
        // todo gcp url change
        // setPadUrl(`https://dev-pad.webnyay.in/p/${padId}`);
        setPadUrl(`http://34.93.241.195:9001/p/${padId}`);
      } else {
        setMeetingUrl(
          `https://${
            isDev ? DEV_WEBNYAY_URL : WEBNYAY_URL
          }/meeting/${disputeId}`
        );
        // setPadUrl(`https://dev-pad.webnyay.in/p/${padId}`);
        setPadUrl(`http://34.93.241.195:9001/p/${padId}`);
      }
    }
  }, [disputeId, padId]);
  async function createDyteMeeting() {
    let uniqueUserId = localStorage.getItem("dyte_uuid") || uuid();
    let uniqueDisputeId = uuid();
    try {
      setLoading(true);
      setErrorType(null);
      setDisputeId(null);
      let response = await instance({
        method: "post",
        url: "/createdytemeetinganonymous",
        data: {
          disputeId: uniqueDisputeId,
          email: email,
          name: name,
          userid: uniqueUserId,
          host: getRealhost(),
        },
      });
      setLoading(false);
      localStorage.setItem("anonymousUserName", name);
      localStorage.setItem("dyte_uuid", uniqueUserId);
      setDisputeId(response?.data?.data.dispute_id);
      setPadId(response?.data?.pad_id);
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.heading} align="center">
        Create Meeting
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            fullWidth
            className={classes.textField}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            className={classes.textField}
            onChange={(e) => setEmail(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            fullWidth
            className={classes.createMeetingButton}
            onClick={createDyteMeeting}
            disabled={loading || !email || !name}
          >
            {loading && (
              <CircularProgress
                style={{ width: 15, height: 15, marginRight: "0.5rem" }}
              />
            )}
            Create Meeting
          </Button>
        </CardActions>
      </Card>
      {(disputeId || errorType) && (
        <Box mt={4}>
          <Paper className={classes.paper}>
            {errorType ? (
              <Typography>{errorType}</Typography>
            ) : (
              <>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ marginLeft: "10px" }}
                >
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={meetingUrl}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={isCopied ? "Copied!" : "Copy"}>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(meetingUrl);
                          setIsCopied(true);
                        }}
                      >
                        <FileCopy color={isCopied ? "primary" : "inherit"} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ marginTop: "16px", marginLeft: "10px" }}
                >
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={padUrl}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={isCopiedPadUrl ? "Copied!" : "Copy"}>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(padUrl);
                          setIsCopiedPadUrl(true);
                        }}
                      >
                        <FileCopy
                          color={isCopiedPadUrl ? "primary" : "inherit"}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Box>
      )}
    </div>
  );
}
