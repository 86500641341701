import React, { useState, useEffect } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import GenerateInvoice from "./Finance/GenerateInvoice";
import instance from "api/globalaxios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  heading: {
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.3px",
    color: "#252733",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
}));

export default function Finances({ profile }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [registeredDisputesList, setregisteredDisputesList] = useState({});

  async function getDisputes() {
    return await instance.get("/tiac_admin_disputes/");
  }
  useEffect(() => {
    getDisputes().then((res) => {
      setregisteredDisputesList([
        ...res.data["Conciliation"],
        ...res.data["Arbitration"],
        ...res.data["closed"],
      ]);
    });
  }, []);

  React.useEffect(() => {
    if(Object.keys(registeredDisputesList).length !== 0)
      setLoading(false);
  }, [registeredDisputesList]);


  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <div className={classes.root}>
        <Grid container justify="flex-start" direction="column">
          <Grid item>
            <Grid
              container
              justify="space-between"
              direction="row"
              style={{ padding: "10px" }}
            >
              <Grid item>
                <Typography className={classes.heading}>
                  Finances
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.username}>
                  {profile.user.first_name} {profile.user.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Box height="5rem"></Box>
            <GenerateInvoice
              opened={true}
              profile={profile}
              registeredDisputesList={registeredDisputesList}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
