import { InputAdornment, OutlinedInput } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {setLogOut,
  setTokenAsync
} from "slices/loginSlice";
import * as Yup from "yup";
import React from "react";
import EnvelopeSimple from 'images/EnvelopeSimple.svg';
import Lock from 'images/Lock.svg';
import GoogleAuth from "features/common/GooglAuth";
import DividerWithText from "features/Divider/DividerWithText";
import Graphics from "./Graphics";
import Logo_Transparent from "../../images/webnyay.svg";

const LoginSchema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  password: Yup.string().required("Password Required"),
});

const firstRowLinks = [
  {
    title : "Neutrals",
    value : "https://webnyay-my.sharepoint.com/:x:/g/personal/admin_webnyay_in/EdTSF-z-OixPvGH-7PF5I1QBfExxXlMG_FVOucpOvkhIBg?e=d693Zz",
    newTab : true
  },
  {
    title : "Awards",
    value : "https://webnyay-my.sharepoint.com/personal/admin_webnyay_in/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fadmin%5Fwebnyay%5Fin%2FDocuments%2FCreative%20Assets%2FRanita%20Backup%2FRedacted%20Awards&ga=1",
    newTab : true
  },
  {
    title : "Analytics",
    value : "https://webnyay-my.sharepoint.com/:x:/g/personal/admin_webnyay_in/EbJqqFdNV2ZOpCZlSrWnYUIBOvQafhpSELURdLg7RC9WQQ?e=nqbVpT",
    newTab : true
  },
];

const secondRowLinks = [
  {
    title: <FormattedMessage id="footer.terms" />,
    value: "/files/termsAndConditions.pdf",
    newTab : true
  },
  {
    title: <FormattedMessage id="footer.privacy" />,
    value: "/files/wenyayPrivacyPolicy.pdf",
    newTab : true
  },
  {
    title: <FormattedMessage id="footer.contact" />,
    value: "/contact",
    newTab : false
  },
];

const renderLinks = (links, classes) => {
  return links.map((link, index) => {
    return (
      <span key={index}>
        <Link
          className={classes.link}
          to={{pathname : link.value}}
          target={link.newTab ? "_blank" : "_self"}
        >
          {link.title}
        </Link>
        {index < links.length-1 && (
          <span>{' | '}</span>
        )}
      </span>
    );
  });
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: '2.625rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letteSpacing: 0,
    textAlign: 'center',
    color: 'black',
    marginBottom: '0.75rem',
  },
  sub_title: {
    color: 'black',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  color_gray: {
    color: '#5A5A5A',
    fontWeight: 400,
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
  },
  input_field: {
    margin: '0.5rem 0',
    borderRadius: 0,
    width: '100%',
  },
  forgot_button: {
    color: '#4A3BFF',
    fontSize: '1rem',
    fontWeight: 500,
    textAlign: 'right',
    margin: '0.6rem 0',
    cursor: 'pointer',
  },
  main_button: {
    color: 'white',
    backgroundColor: '#4A3BFF',
    textTransform: 'none',
    border: '1px solid #4A3BFF',
    borderRadius: 0,
    padding: '0.7rem',
    boxShadow: 'none',
    fontSize: '1.125rem',
    fontWeight: 700,
    "&:hover": {
      color: '#4A3BFF',
      backgroundColor: 'transparent',
      border: '1px solid #4A3BFF',
      boxShadow: 'none',
    },
  },
  space_between_input_fields: {
    height: '1rem',
  },
  space_between_buttons: {
    height: '2.6rem',
  },
  leftHandSide: {
    width: '50%',
    ['@media (max-width: 700px)']: {
      display: 'none',
    },
  },
  rightHandSide: {
    width: '50%',
    backgroundColor: 'white',
    ['@media (max-width: 700px)']: {
      width: '100%',
    },
  },
  link: {
    color: 'black',
  }
}));

const Signin = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { state: routeState } = useLocation();
  const [currentScreen, setCurrentScreen] = useState(1);
  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(setLogOut());
      let { email, password } = values;
      dispatch(setTokenAsync(email, password)).then(() => {
        if (routeState) {
          history.push(`${routeState.from.pathname}`);
        } else {
          history.push("/");
        }
      });
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  React.useEffect(() => {
    dispatch(setLogOut()); // to remove login error alert
  }, []);

  return (
    <Grid container style={{height:"100%"}}>
      <Grid item  className={classes.leftHandSide} style={ currentScreen === 2 ? { background:'#4A3BFF'} : {background : '#CCE6FF'} } >
        <Graphics currentScreen={currentScreen} setCurrentScreen={setCurrentScreen}/>
      </Grid>
      <Grid item  className={classes.rightHandSide}>
        <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box className={classes.paper}>
          <Box style={{width:'11.4rem'}}>
            <img src={Logo_Transparent} alt="logo" style={{width:'100%'}}/>
            <div style={{height:'1rem'}} />
          </Box>
          <Typography className={classes.title}>
            <FormattedMessage id="auth.login" />
          </Typography>
          <Typography className={`${classes.sub_title} ${classes.color_gray}`}>
            Your log in details have been shared over email
          </Typography>
          <Box style={{height:'3rem'}} />
          <form
            className={classes.form}
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <GoogleAuth />
            <Box style={{height:'1.5rem'}} />
            <DividerWithText>OR</DividerWithText>
            <Box style={{height:'1.5rem'}} />
            <Typography id="email" className={classes.label}>Email address</Typography>
            <OutlinedInput
              margin="normal"
              className={classes.input_field}
              value={formik.email}
              required
              id="email"
              name="email"
              autoComplete="email"
              placeholder="Eg - Myemail@mail.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              startAdornment={<InputAdornment position="start"><img src={EnvelopeSimple} alt="Envelope" /></InputAdornment>}
            />
            {formik.errors.email && formik.touched.email && (
              <Alert severity="error">{formik.errors.email}</Alert>
            )}
            {hasErrors && apiErrors.email && (
              <Alert severity="error">{apiErrors.email[0]}</Alert>
            )}
            <Box className={classes.space_between_input_fields} />
            <Typography id="password" className={classes.label}><FormattedMessage id="auth.password" /></Typography>
            <OutlinedInput
              className={classes.input_field}
              margin="normal"
              required
              name="password"
              type={passwordType}
              id="password"
              value={formik.password}
              autoComplete="current-password"
              placeholder="At least 6 characters"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              startAdornment={<InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>}
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer", color: '#5a5a5a' }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </InputAdornment>
              }
            />
            {formik.errors.password && formik.touched.password && (
              <Alert severity="error">{formik.errors.password}</Alert>
            )}
            {hasErrors && apiErrors.password && (
              <Alert severity="error">{apiErrors.password[0]}</Alert>
            )}
            <Grid container style={{justifyContent:"end",}}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Link to="/forgot-password">
                  <Typography className={classes.forgot_button}>{<FormattedMessage id="auth.forgot" />}</Typography>
                </Link>
              </Grid>
            </Grid>
            <Box className={classes.space_between_buttons} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.main_button}
            >
              {<FormattedMessage id="auth.login" />}
            </Button>
            {hasErrors && apiErrors.non_field_errors && (
              <Alert severity="error">{apiErrors.non_field_errors[0]}</Alert>
            )}
            <div style={{height:'2rem'}} />
          </form>
        </Box>
        <Typography style={{textAlign: 'center'}}>
          {renderLinks(firstRowLinks,classes)}
          <br />
          {renderLinks(secondRowLinks,classes)}
        </Typography>
        <div style={{height:'1rem'}} />
        </Container>
      </Grid>
    </Grid>
  );
};

export default Signin;
